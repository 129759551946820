<template>

<div class="js-slider stage-video block-stage block-stage--regular stage-video--disable-nav" data-slider-stage="" data-slider-stage-disable="stage-video--disable-nav" data-slider-video="" data-slider-autoplay="true" data-slider-stage-playing="stage-video--playing">
    <div class="js-slider-items block-stage__items stage-video__items slick-initialized slick-slider">
        <div aria-live="polite" class="slick-list draggable">
            <div class="slick-track" role="listbox" style="opacity: 1; width: 1903px; transform: translate3d(0px, 0px, 0px);">
                <div class="block-stage__item slick-slide slick-current slick-active" tabindex="-1" role="option" aria-describedby="slick-slide00" data-slick-index="0" aria-hidden="false" style="width: 1903px;">
                    <picture class="block-stage__picture block-stage__picture--only">
                        <source media="(max-width: 360px)" srcset="/imagenes/servicio_cliente/5.png">
                        <source media="(max-width: 479px)" srcset="/imagenes/servicio_cliente/4.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/servicio_cliente/3.png">
                        <source media="(max-width: 1024px)" srcset="/imagenes/servicio_cliente/2.png">
                        <source media="(min-width: 1025px)" srcset="/imagenes/servicio_cliente/1.png">
                        <img src="" alt="DB Schenker Flags in the Sky" class="block-stage__img">
                    </picture>
                    <div class="block-stage__content wrapper wrapper--gutter"></div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container">

  <h1>Contáctenos</h1>

  <div class="row">
    <div class="col-lg"></div>            
    <div class="col-lg-8">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="nombre" placeholder="Nombre">
          <label for="nombre">Nombre</label>
        </div>

        <div class="form-floating mb-3">
          <input type="email" class="form-control" id="correo" placeholder="name@example.com">
          <label for="correo">Correo electrónico</label>
        </div>

        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="telefono" placeholder="Número de teléfono">
          <label for="telefono">Número de teléfono</label>
        </div>

        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="asunto" placeholder="Asunto">
          <label for="asunto">Asunto</label>
        </div>

        <div class="form-floating">
          <textarea class="form-control" placeholder="Leave a comment here" id="mensaje" style="height: 100px"></textarea>
          <label for="mensaje">Mensaje</label>
        </div>
        <br>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            Enviar copia a sí mismo
          </label>
        </div>

        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <button class="btn btn--solid btn--default btn--pointer"> Enviar </button>       
        </div>
    </div>
    <div class="col-lg"></div> 
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style>
img.btn-whatsapp {
    left: 80px;
}
.grecaptcha-badge { visibility: visible }
</style>>

