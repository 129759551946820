<template>
<Datoscontacto />
<br>
</template>
<script>
import Datoscontacto from '@/components/Datoscontacto.vue'

export default {
 
  name: 'Contactenos',
 
  components: {
    Datoscontacto, 
  },

  data: () => ({
  //
  }),

};
</script>

